@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-display: swap;
    font-family: "Founders";
    font-weight: 300;
    src: url("../../public/fonts/founders.woff2") format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: "Itc";
    font-style: italic;
    font-weight: 300;
    src: url("../../public/fonts/itc.woff2") format("woff2");
  }
}

body {
  @apply font-founders antialiased;
}

em {
  font-style: italic;
  @apply font-itc;
}

html {
  /* // 1 rem = 10px with the following dimensions */
  font-size: calc(10 / 375 * 100vw);
}
@media (min-width: 900px) {
  html {
    /* // Desktop scaling */
    font-size: calc(10 / 1440 * 100vw);
  }
}

@media (min-width: 1900px) {
  html {
    /* // max width scaling to 1500 */
    font-size: calc(10 / 1440 * 1700px);
  }
}

/* Root variable to adjust calcs */
:root {
  --design-size: 37.5;
}

@media (min-width: 900px) {
  :root {
    --design-size: 144;
  }
}

.wrapper {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
}

/**
 * Chrome has a bug with transitions on load since 2012!
 *
 * To prevent a "pop" of content, you have to disable all transitions until
 * the page is done loading.
 *
 * https://lab.laukstein.com/bug/input
 * https://twitter.com/timer150/status/1345217126680899584
 */
body.loading * {
  transition: none !important;
}
